export default {
  filters: {
    xref (value) {
      try {
        value = JSON.parse(value)
      } catch (e) {

      }

      if (Array.isArray(value)) {
        value = value.map((item) => {
          return (typeof item.name !== 'undefined' ? item.name : item)
        }).join(',')
      }

      return value
    }
  }
}
